import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import Modal from '../../../molecule/modal/Modal';
import Clock from '../../../atom/icon/Clock';
import LabelTextModal from '../../../atom/labelText/LabelTextModal';
import WorkflowItem from '../../../molecule/workflowItem/WorkflowItem';
import XClose from '../../../atom/icon/XClose';
import { Check } from '../../../atom/icon';
import Button from '../../../atom/button/Button';
import LinkExternal from '../../../atom/icon/LinkExternal';
import {
  OverlapTaskEmployeeType,
  TaskOverviewType,
} from '../../../../@types/HomeTypes';
import {
  formatUnixToDateTime,
  formatUnixToDayYearMonthDate,
  formatUnixToNumberYearMonthDay,
} from '../../../../utils/date';
import {
  useApprovalTaskMutation,
  useDetailTimeOffRequestQuery,
  useGetOverlapTaskEmployeeQuery,
} from '../../../../store/feature/service/homeApiSlice';

interface ApprovalLeaveProps {
  data: TaskOverviewType | null;
  handleClose: any;
}
const ApprovalLeave = ({ data, handleClose }: ApprovalLeaveProps) => {
  const { palette } = useTheme();
  const [statusCodeApproval, setStatusCodeApproval] = useState('');
  const [approvalTaskMutation, { isLoading }] = useApprovalTaskMutation();
  const { data: detailData } = useDetailTimeOffRequestQuery(data?.detailId);
  const { data: overlapEmployees } = useGetOverlapTaskEmployeeQuery({
    id: data?.detailId || '',
  });
  const sendApproval = async (statusCode: string) => {
    setStatusCodeApproval(statusCode);
    await approvalTaskMutation({
      id: data?.id,
      statusCode,
    });
    handleClose();
  };
  return !data ? (
    <div />
  ) : (
    <Modal
      fullHeight
      open
      onClose={handleClose}
      showCloseIcon
      title="Time Off Request"
      leftButtonProps={{
        variant: 'outlined',
        isLoading: statusCodeApproval === 'REJECTED' && isLoading,
        disabled: isLoading,
        onClick: () => {
          sendApproval('REJECTED');
        },
      }}
      rightButtonProps={{
        variant: 'outlined',
        isLoading: statusCodeApproval === 'APPROVED' && isLoading,
        disabled: isLoading,
        onClick: () => {
          sendApproval('APPROVED');
        },
      }}
      leftButtonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.red[50],
            },
          }}
        >
          <XClose />
          Reject
        </Box>
      }
      rightButonLabel={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            '& svg path': {
              stroke: palette.green[50],
            },
          }}
        >
          <Check />
          Approve
        </Box>
      }
      icon={
        <Box
          sx={{
            background: palette.blue[20],
            height: '48px',
            width: '48px',
            borderRadius: '90px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
              width: '20px',
              height: '20px',
            },
            '& svg path': {
              stroke: palette.blue[90],
              strokeWidth: '2',
            },
          }}
        >
          <Clock />
        </Box>
      }
    >
      <Grid container gap="16px" sx={{ overflow: 'auto' }}>
        <LabelTextModal
          label="ID"
          texts={[detailData?.timeOffRequestId ?? '']}
        />
        <LabelTextModal
          label="Employee"
          texts={[
            `(${detailData?.employeeNumber}) ${detailData?.employeeFullName}`,
          ]}
        />
        <LabelTextModal label="Type" texts={[detailData?.typeName ?? '']} />
        <LabelTextModal
          label="Duration"
          texts={[detailData?.durationName ?? '']}
        />
        <LabelTextModal
          label="Date"
          texts={detailData?.dates.map(e => formatUnixToDayYearMonthDate(e))}
        />

        {overlapEmployees && overlapEmployees.length > 0 && (
          <Grid
            item
            container
            direction="column"
            sx={{
              backgroundColor: palette.orange[20],
              paddingY: '12px',
              paddingX: '16px',
            }}
          >
            <Typography variant="body14" color={palette.gray[100]}>
              Selected date will overlap with other
            </Typography>
            <Typography variant="body14" color={palette.gray[100]}>
              teammate's time off:
            </Typography>
            <ul style={{ marginBlockStart: 0 }}>
              {overlapEmployees?.map((e: OverlapTaskEmployeeType) => (
                <li key={e.fullName}>
                  <Typography variant="body14" color={palette.gray[100]}>
                    {e.fullName} ({formatUnixToNumberYearMonthDay(e.date)})
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
        )}
        <LabelTextModal
          label="Total Day(s)"
          texts={[`${detailData?.totalDay.toFixed(1)} Day(s)`]}
        />
        <LabelTextModal
          label="Attachment"
          textComponents={[
            detailData?.attachment?.fileName ? (
              <Button
                onClick={() =>
                  window.open(detailData?.attachment?.fileUrl, '_blank')
                }
                variant="link"
              >
                <Typography
                  variant="body14"
                  fontWeight={600}
                  color={palette.blue[50]}
                >
                  {detailData?.attachment?.fileName}
                </Typography>
                <LinkExternal />
              </Button>
            ) : (
              <div>-</div>
            ),
          ]}
        />
        <LabelTextModal label="Remarks" texts={[detailData?.remarks ?? '']} />
        <Grid
          item
          sx={{ borderTop: `1px solid ${palette.gray[50]}`, width: '100%' }}
        />
        <Grid item>
          <Typography
            variant="body16"
            fontWeight={700}
            display="block"
            color={palette.gray[90]}
          >
            Workflow
          </Typography>
        </Grid>
        <WorkflowItem
          title={detailData?.employeeFullName}
          image={
            <img
              src={detailData?.profileURL}
              alt="employee profile"
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
            />
          }
          description={formatUnixToDateTime(data.createdAt)}
        />
      </Grid>
    </Modal>
  );
};

export default ApprovalLeave;
